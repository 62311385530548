import { ApolloError, gql, useQuery } from '@apollo/client';
import { EpEmployee } from 'types/graphql-types';
import { useLoginInput } from './use-login-input';

type UseQueryEmployeeResponse = {
  employee: EpEmployee;
};

export type UseResult = {
  loading: boolean;
  error: ApolloError | undefined;
  employee: EpEmployee | undefined;
};

const EMPLOYEE_FIELDS = `
  lastUpdated
  certificates {
    id
    vendor
    certificate
    image
    validFrom
    validTo
    comment
  }
  contractFromDate
  contractToDate
  contractCity
  contractCountry
  profileLink
  experienceInsideCompany
  experienceOutsideCompany
  country
  city
  department
  firstName
  id
  interests
  englishLevel
  languages {
    name
    level
  }
  lastName
  linkedIn
  login
  officeLocation
  photo
  platforms {
    name
    level
    desc
  }
  domains {
    name
    subDomains
    desc
  }
  qualification
  technologies {
    name
    level
    desc
  }
  benefitCompensationRequests {
    status
    type
    compensationRequest {
      id
    }
  }
`;

export const GET_EMPLOYEE = gql`
  query GetEmployee($login: String!) {
    employee(login: $login) {
      ${EMPLOYEE_FIELDS}
    }
  }
`;

export const GET_PROFILE = gql`
  query GetProfile($profileLinkHash: String!) {
    employee(profileLinkHash: $profileLinkHash) {
      ${EMPLOYEE_FIELDS}
    }
  }
`;

export const useEmployee = (login?: string): UseResult => {
  const { login: loggedUserLogin } = useLoginInput();

  const { loading, error, data } = useQuery<UseQueryEmployeeResponse>(
    GET_EMPLOYEE,
    {
      variables: { login: login || loggedUserLogin },
    },
  );

  if (error || !data) {
    // throw error;
  }

  return { employee: data?.employee, error, loading };
};

export const useProfile = (profileLinkHash: string): UseResult => {
  const { loading, error, data } = useQuery<UseQueryEmployeeResponse>(
    GET_PROFILE,
    {
      variables: { profileLinkHash },
    },
  );

  if (error || !data) {
    // throw error;
  }

  return { employee: data?.employee, error, loading };
};
