import { QueryResult, gql, useQuery } from '@apollo/client';
import { EpEmployee } from 'types/graphql-types';

export type PersonalData = {
  employee: EpEmployee;
};

const GET_PERSONAL_DATA = gql`
  query GetPersonalData($dashboardLinkHash: String!) {
    employee(dashboardLinkHash: $dashboardLinkHash) {
      login
      firstName
      lastName
      photo
      officeLocation
      country
      city
      qualification
      englishLevel
      contractFromDate
      contractToDate
      certificates {
        certificate
      }
      domains {
        name
      }
      interests
      languages {
        name
      }
      linkedIn
      technologies {
        name
      }
      benefitCompensationRequests {
        status
        type
        compensationRequest {
          id
        }
      }
    }
  }
`;

export const useDashboardData = (
  dashboardLinkHash?: string,
): QueryResult<PersonalData> =>
  useQuery<PersonalData>(GET_PERSONAL_DATA, {
    skip: !dashboardLinkHash,
    variables: { dashboardLinkHash },
  });
