/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Space, Typography } from 'antd';
import classNames from 'classnames';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { LaptopIcon } from 'components/icons';
import { Analytics } from 'constants/analytics';
import { useEmployee } from 'hooks/use-employee';
import { VMS_SICK_LEAVES } from 'src/config';
import { SocialSickLeaveDurationType } from 'types/vms';
import { useSickLeaves } from './query';
import styles from './styles.less';

const { Text } = Typography;

export const SickLeavesBlock: FC<{ login: string }> = ({ login }) => {
  const { employee } = useEmployee();
  const { data, loading, error } = useSickLeaves(login);

  const sickLeavesAvailable: number =
    data?.vms?.avilableSocialSickLeavesDuration || 0;

  const headerClasses = classNames(styles['d-inline'], styles['mr-6']);

  const { t } = useTranslation('translation');

  const onTakeSickLeave = (event: MouseEvent): void => {
    event?.stopPropagation();
    window.open(VMS_SICK_LEAVES, '_blank')?.focus();
  };

  if (!error && !data?.vms) {
    return (
      <Card
        icon={<LaptopIcon />}
        iconClassNames={styles['icon-class']}
        title={t('Social sick leaves')}
        containerClassNames={styles['card']}
        onClick={onTakeSickLeave}
        analyticsDomainName={Analytics.VMS_SICK_LEAVES}
      >
        <Header level={1} className={styles['d-inline']}>
          ...
        </Header>
        <Text type="secondary">No information</Text>
      </Card>
    );
  }

  const durationInDays =
    data?.vms.socialSickLeaveDurationType === SocialSickLeaveDurationType.DAYS;

  const type = durationInDays ? t('days') : t('hours');

  return (
    <Card
      loading={loading}
      error={error}
      title={t('Social sick leaves')}
      extra={
        employee?.login === login && (
          <Button onClick={onTakeSickLeave}>{t('Take sick leave')}</Button>
        )
      }
      icon={<LaptopIcon />}
      iconClassNames={styles['icon-class']}
      containerClassNames={styles['card']}
      onClick={onTakeSickLeave}
      analyticsDomainName={Analytics.VMS_SICK_LEAVES}
    >
      <Space direction="vertical" className={styles['space']}>
        <div>
          <Header className={headerClasses}>
            {sickLeavesAvailable} {type}
          </Header>
          <Text>{t('available')}</Text>
        </div>
      </Space>
    </Card>
  );
};
