import { QueryResult, gql, useQuery } from '@apollo/client';
import { useLoginInput } from 'hooks';
import { EpEmployee } from 'types/graphql-types';

type HeaderData = {
  employee: Pick<
    EpEmployee,
    'linkedIn' | 'contractFromDate' | 'contractToDate'
  >;
};

const GET_HEADER = gql`
  query GetHeader($login: String!) {
    employee(login: $login) {
      linkedIn
    }
  }
`;

export const useHeader = (): QueryResult<HeaderData> =>
  useQuery<HeaderData>(GET_HEADER, { variables: useLoginInput() });
