import { useEffect } from 'react';

const disableBodyScroll = (): void => {
  document.body.style.setProperty('overflow', 'hidden', 'important');
};

const enableBodyScroll = (): void => {
  document.body.style.removeProperty('overflow');
};

export const useDisableBodyScroll = (disable: boolean): void => {
  useEffect(() => {
    if (disable) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }

    return enableBodyScroll;
  }, [disable]);
};
