import React from 'react';
import { useGetAppRoutes } from 'constants/menu-routes';
import { MenuLink } from './MenuItem';
import styles from './styles.less';

type IProps = {
  readonly collapsed: boolean;
};

export const Navigation = ({ collapsed }: IProps): React.ReactNode => {
  const appRoutes = useGetAppRoutes();

  return (
    <nav className={styles.groupsNavigation}>
      <div className={styles.groupNavigation}>
        <ul className={styles.navigation}>
          {appRoutes.map((appRoute) => (
            <li key={appRoute.title}>
              <ul>
                {appRoute.routes.map(({ icon, title, route, onClick }) => (
                  <li key={title}>
                    <MenuLink
                      collapsed={collapsed}
                      icon={<div className={styles.badgeWrap}>{icon}</div>}
                      text={title}
                      path={route}
                      onClick={onClick}
                    ></MenuLink>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};
