import React, { FC } from 'react';
import Title from 'antd/lib/typography/Title';
import classNames from 'classnames';

export enum HeaderLevel {
  First = 1,
  Second = 2,
  Third = 3,
  Fourth = 4,
  Fifth = 5,
}

export const Header: FC<HeaderProps> = ({
  children,
  sub,
  level,
  className,
}) => {
  const classes = classNames({ sub }, className);

  return (
    <Title level={level} className={classes}>
      {children}
    </Title>
  );
};

type HeaderProps = {
  children?: React.ReactNode;
  sub?: boolean;
  level?: HeaderLevel;
  className?: string;
};
