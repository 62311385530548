import { QueryResult, gql, useQuery } from '@apollo/client';
import { EmployeeVacation } from 'types/graphql-types';

type VmsDataResult = {
  vms?: EmployeeVacation;
};

const GET_VACATIONS_DATA = gql`
  query GetSickLeaves($login: String!) {
    vms(login: $login) {
      hasActiveStatusVms
      accumulatedCalendarDays
      accumulatedWorkingDays
      availableWorkingDays
    }
  }
`;

export const useVms = (login?: string): QueryResult<VmsDataResult> =>
  useQuery<VmsDataResult>(GET_VACATIONS_DATA, {
    skip: !login,
    variables: { login },
  });
