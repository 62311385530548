import { useEffect, useMemo } from 'react';
import { useMsal } from '@azure/msal-react';

export enum Entity {
  A1QA = 'A1QA',
  Itransition = 'Itransition',
}

export enum Environment {
  production = 'production',
  stage = 'stage',
}

declare global {
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface IntrinsicElements {
      'app-switcher': {
        collapsed: boolean;
        entity: string;
        environment: string;
        name: string;
        portalName: string;
        id?: string;
      };
    }
  }
}

export const useCommonUIElements = (): {
  currentPortalName: string;
  entityName: Entity;
  environment: Environment;
} => {
  const { accounts } = useMsal();
  const url = window.location.href;

  const isA1QAAccounts = useMemo(
    () => accounts.every(({ username }) => username.includes('a1qa')),
    [accounts],
  );

  const currentPortalName = 'Home';

  const entityName = useMemo(
    () => (isA1QAAccounts ? Entity.A1QA : Entity.Itransition),
    [isA1QAAccounts],
  );

  const environment = useMemo(
    () =>
      url.includes('demohoster') || url.includes('localhost')
        ? Environment.stage
        : Environment.production,
    [url],
  );

  useEffect(() => {
    const script = document.createElement('script');

    script.src = process.env.REACT_APP_COMMON_UI_ELEMENTS_URL;
    script.type = 'module';
    script.defer = true;

    document.body.appendChild(script);
  }, []);

  return { currentPortalName, entityName, environment };
};
