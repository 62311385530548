import React, { PropsWithChildren, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { MenuButton } from 'components/side-menu/components/Navigation/MenuItem/MenuButton';
import styles from './styles.less';

type IProps = {
  readonly icon: ReactNode;
  readonly text: string;
  readonly collapsed: boolean;
  readonly isActiveLink?: boolean;
  readonly path?: string;
  readonly onClick?: () => void;
};

export const MenuLink = ({
  icon,
  text,
  path,
  collapsed,
  children,
  isActiveLink,
  onClick,
}: PropsWithChildren<IProps>): React.ReactNode => {
  const getNavLinkClassName = ({ isActive }: { isActive: boolean }): string =>
    classNames(
      styles.navigationItem,
      styles.menuLink,
      (isActiveLink || isActive) && styles.active,
    );

  return (
    <Tooltip title={collapsed ? text : null} placement="right">
      <div>
        {path ? (
          <NavLink to={path} className={getNavLinkClassName}>
            <div className={styles.iconContainer}>
              {icon}
              <span className={styles.text}>{text}</span>
            </div>
            {children}
          </NavLink>
        ) : (
          <MenuButton
            icon={icon}
            text={text}
            collapsed={collapsed}
            onClick={onClick}
          ></MenuButton>
        )}
      </div>
    </Tooltip>
  );
};
