export const msalConfig = {
  auth: {
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_MSAL_TENANT_ID}`,
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    redirectUri: process.env.REACT_APP_FRONTEND,
  },
};

export const loginSilentRequest = {
  scopes: [`${process.env.REACT_APP_MSAL_CLIENT_ID}/user.read`],
};
