import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';
import classNames from 'classnames';
import { Navigation } from 'components/side-menu/components/Navigation';
import { SearchBar } from 'components/side-menu/components/SearchBar';
import { UserMenu } from 'components/side-menu/components/UserMenu';
import { useCommonUIElements, useDevice, useDisableBodyScroll } from 'hooks';
import { useRole } from 'hooks/use-role';
import { allowSearchEmployees } from 'types/user-roles';
import packageJson from '../../../../package.json';
import { ArrowDoubleRightIcon, HamburgerMenuIcon } from './../icons';
import styles from './styles.less';

const { Sider } = Layout;

export const SideMenu: FC = (): React.ReactNode => {
  const location = useLocation();
  const { isMobile, isDesktop } = useDevice();
  const [collapsed, setCollapsed] = useState(!isDesktop);
  const { data: roleData } = useRole();

  const showSearchBar = roleData && allowSearchEmployees(roleData.role.role);

  const { entityName, environment, currentPortalName } = useCommonUIElements();
  const SIDEBAR_MAX_WIDTH = 220;

  const SIDEBAR_COLLAPSED_WIDTH = 75;

  useEffect(() => {
    if (!isDesktop) {
      setCollapsed(true);
    }
  }, [isDesktop, location.pathname]);

  useDisableBodyScroll(isMobile && !collapsed);

  return (
    <Sider
      className={styles.sideBar}
      width={isMobile ? '100vw' : SIDEBAR_MAX_WIDTH}
      collapsible
      collapsed={collapsed}
      collapsedWidth={isMobile ? '100vw' : SIDEBAR_COLLAPSED_WIDTH}
      onCollapse={setCollapsed}
      trigger={null}
    >
      <div className={styles.headerSideBar}>
        <div className={styles.controlSubMenu}>
          <app-switcher
            id="portals"
            name="Internal systems"
            portalName={currentPortalName}
            environment={environment}
            entity={entityName}
            collapsed={isMobile ? false : collapsed}
          />

          <div className={styles.nameLogo}>HOME</div>
          {isMobile && (
            <HamburgerMenuIcon
              className={classNames(
                styles.toggleSidebarIcon,
                collapsed && [styles.sidebarCollapsed],
              )}
              onClick={() => setCollapsed((wasCollapsed) => !wasCollapsed)}
            />
          )}
        </div>
        <div className={styles.containerGradient}> </div>
        <UserMenu collapsed={collapsed && !isMobile} />
      </div>
      {showSearchBar && !collapsed && (
        <div className={styles.searchBar}>
          <SearchBar />
        </div>
      )}
      <div className={styles.navigation}>
        <Navigation collapsed={collapsed} />
      </div>
      <div className={styles.footerSideBar}>
        <div className={styles.footerContainer}>
          <div className={styles.version}>v{packageJson.version}</div>
          {!isMobile && (
            <button
              type="button"
              className={styles.collapseSidebarButton}
              onClick={() => setCollapsed(!collapsed)}
            >
              <ArrowDoubleRightIcon className={styles.collapseIcon} />
            </button>
          )}
        </div>
      </div>
    </Sider>
  );
};
