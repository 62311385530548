import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { AutoComplete, Input } from 'antd';
import { SearchIcon } from 'components/icons';
import {
  UserInfo,
  UserInfoProps,
} from 'components/side-menu/components/UserInfo';
import { getViewDashboardLink } from 'constants/routes';
import { useImageBlobSrc } from 'hooks';
import { useSearch } from 'hooks/use-search';
import styles from './styles.less';

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const skeletonOptions = [1, 2, 3].map((n) => ({
  label: (
    <UserInfo
      key={`loading-${n}`}
      login=""
      lastName=""
      firstName=""
      loading={true}
    />
  ),
  value: `loading-${n}`,
}));

const UserInfoWrapper: FC<UserInfoProps> = (props) => {
  const { src } = useImageBlobSrc(props.avatarImg || '');

  return <UserInfo {...props} avatarImg={src || undefined} />;
};

export const SearchBar = (): React.ReactNode => {
  const { employees, loading, search } = useSearch();

  const options = loading
    ? skeletonOptions
    : employees.map((ep) => ({
        label: (
          <Link to={getViewDashboardLink(ep.dashboardLink)}>
            <UserInfoWrapper
              firstName={ep.firstName || ''}
              lastName={ep.lastName || ''}
              login={ep.login || ''}
              avatarImg={ep.photo || undefined}
              qualification={ep.qualification || ''}
            />
          </Link>
        ),
        value: ep.login,
      }));

  const onSearch = (text: string): void => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    if (text.length < 2) {
      return;
    }

    void search(text);
  };

  return (
    <AutoComplete
      options={options}
      onSearch={onSearch}
      allowClear
      dropdownMatchSelectWidth={false}
      getPopupContainer={(trigger) => trigger.parentElement}
      popupClassName={styles.searchDropdown}
    >
      <Input placeholder="Search by name" prefix={<SearchIcon />} />
    </AutoComplete>
  );
};
