import React, { FC } from 'react';
import { Alert, Col, Divider, PageHeader, Row, Spin } from 'antd';
import classNames from 'classnames';
import {
  ConfluenceIcon,
  EngageIcon,
  InfoLogoIcon,
  JiraIcon,
  OfficeLogoIcon,
  PasswordResetIcon,
  ServiceDeskIcon,
} from 'components/icons';
import { Link } from 'components/link';
import { useEmployee } from 'hooks';
import {
  EPBlock,
  OpportunitiesBlock,
  PersonalBlock,
  QMSBlock,
  SickLeavesBlock,
  VMSBlock,
  WebCVBlock,
  ZPBlock,
} from 'user';
import { SocialBenefitBlock } from 'user/social-benefit-block';
import styles from './styles.less';

export const Dashboard: FC = () => {
  const { employee, error, loading } = useEmployee();
  const footerLinkClass = styles['footer-link'];
  const footerLinkIconClass = styles['icon'];

  const GUTTER_16 = 16;
  const GUTTER_12 = 12;

  const SPAN_FULL = 24;

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const SPAN_ONE_SECOND = Math.floor(SPAN_FULL / 2);
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const SPAN_ONE_THIRD = Math.floor(SPAN_FULL / 3);

  if (loading) {
    return <Spin className={styles['spinner']} />;
  }

  if (error || !employee) {
    return <Alert type="error" message={error?.message} />;
  }

  return (
    <>
      <Row gutter={[GUTTER_16, GUTTER_16]} align="stretch">
        <Col span={SPAN_FULL}>
          <PersonalBlock employee={employee} loading={loading} error={error} />
        </Col>
        <Col span={SPAN_FULL} lg={{ span: SPAN_ONE_THIRD }}>
          <ZPBlock />
        </Col>
        <Col span={SPAN_FULL} lg={{ span: SPAN_ONE_THIRD }}>
          <VMSBlock login={employee.login} />
        </Col>
        <Col span={SPAN_FULL} lg={{ span: SPAN_ONE_THIRD }}>
          <SickLeavesBlock login={employee.login} />
        </Col>
        <Col span={SPAN_FULL} lg={{ span: SPAN_ONE_THIRD }}>
          <EPBlock login={employee.login} />
        </Col>
        <Col span={SPAN_FULL} lg={{ span: SPAN_ONE_THIRD }}>
          <OpportunitiesBlock login={employee.login} />
        </Col>
        <Col span={SPAN_FULL} lg={{ span: SPAN_ONE_THIRD }}>
          <QMSBlock login={employee.login} />
        </Col>
        <Col span={SPAN_FULL} lg={{ span: SPAN_ONE_SECOND }}>
          <WebCVBlock login={employee.login} />
        </Col>
        <Col span={SPAN_FULL} lg={{ span: SPAN_ONE_SECOND }}>
          <SocialBenefitBlock benefits={employee.benefitCompensationRequests} />
        </Col>
      </Row>
      <Divider />
      <Row gutter={[GUTTER_12, GUTTER_12]}>
        <Col flex={'auto'} className={footerLinkClass}>
          <Link
            to="https://engage.cloud.microsoft/main/feed"
            name="engage-link"
          >
            <PageHeader ghost={false}>
              <EngageIcon className={footerLinkIconClass} />
            </PageHeader>
          </Link>
        </Col>
        <Col flex={'auto'} className={footerLinkClass}>
          <Link to="https://www.office.com" name="office-link">
            <PageHeader ghost={false}>
              <OfficeLogoIcon className={footerLinkIconClass} />
            </PageHeader>
          </Link>
        </Col>
        <Col
          flex={'auto'}
          className={classNames(footerLinkClass, styles.password)}
        >
          <Link to="https://aka.ms/sspr" name="info-link">
            <PageHeader ghost={false}>
              <PasswordResetIcon
                className={footerLinkIconClass}
                title={'Change password'}
              />
            </PageHeader>
          </Link>
        </Col>
        <Col flex={'auto'} className={footerLinkClass}>
          <Link to="https://wiki.itransition.com" name="confluence-link">
            <PageHeader ghost={false}>
              <ConfluenceIcon className={footerLinkIconClass} />
            </PageHeader>
          </Link>
        </Col>
        <Col flex={'auto'} className={footerLinkClass}>
          <Link to="https://jira.itransition.com" name="jira-link">
            <PageHeader ghost={false}>
              <JiraIcon className={footerLinkIconClass} />
            </PageHeader>
          </Link>
        </Col>
        <Col flex={'auto'} className={footerLinkClass}>
          <Link to="https://info.itransition.com" name="info-link">
            <PageHeader ghost={false}>
              <InfoLogoIcon className={footerLinkIconClass} />
            </PageHeader>
          </Link>
        </Col>
        <Col flex={'auto'} className={footerLinkClass}>
          <Link to="https://servicedesk.itransition.com" name="info-link">
            <PageHeader ghost={false}>
              <ServiceDeskIcon className={footerLinkIconClass} />
            </PageHeader>
          </Link>
        </Col>
      </Row>
    </>
  );
};
