import React from 'react';
import { useTranslation } from 'react-i18next';
import { ChartPieIcon, ExitIcon, ReportIcon } from 'components/icons';
import { useLogout } from 'hooks/use-logout';
import { IO, QMS, VMS_GENERAL, WEBCV, ZP_INFO } from 'src/config';
import { ROOT } from './routes';

export type NavigationRoute = {
  icon?: React.ReactNode;
  title: string;
  route?: string;
  onClick?: () => void;
};

export type NavigationRouteGroup = {
  title: string;
  routes: NavigationRoute[];
};

export const useGetAppRoutes = (): NavigationRouteGroup[] => {
  const { t } = useTranslation();
  const logout = useLogout();

  return [
    {
      routes: [
        {
          icon: <ChartPieIcon />,
          route: ROOT,
          title: t('My dashboard'),
        },
        {
          icon: <ReportIcon />,
          // eslint-disable-next-line
          // @ts-ignore
          onClick: window.showCollectorDialogCallback as () => void,
          title: t('Report a problem'),
        },
        {
          icon: <ExitIcon />,
          onClick: logout,
          title: t('logOut'),
        },
      ],
      title: t('Navigation'),
    },
  ];
};

export const externalSystemsPortals = [
  {
    fill: '#8C1E7A',
    href: '/',
    name: 'Home',
  },
  {
    fill: '#347000',
    href: IO,
    name: 'Opportunities',
  },
  {
    fill: '#157B75',
    href: ZP_INFO,
    name: 'ZP',
  },
  {
    fill: '#2E76D4',
    href: VMS_GENERAL,
    name: 'VMS',
  },
  {
    fill: '#AF4F1A',
    href: QMS,
    name: 'QMS',
  },
  {
    fill: '#84B5F4',
    href: WEBCV,
    name: 'Web CV',
  },
];
