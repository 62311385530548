import { QueryResult, gql, useLazyQuery } from '@apollo/client';
import { EpEmployeePreview } from 'types/graphql-types';

type SearchQueryResponse = {
  employeePreview: EpEmployeePreview[];
};

const SEARCH_EMPLOYEE = gql`
  query SearchEmployee($searchTerm: String!) {
    employeePreview(searchTerm: $searchTerm) {
      login
      firstName
      lastName
      qualification
      photo
      dashboardLink
    }
  }
`;

type useSearchReturnType = {
  employees: EpEmployeePreview[];
  search: (searchTerm: string) => Promise<void>;
} & Pick<QueryResult, 'loading' | 'error'>;

export const useSearch = (): useSearchReturnType => {
  const [refetch, { loading, data, error }] =
    useLazyQuery<SearchQueryResponse>(SEARCH_EMPLOYEE);

  const search = async (searchTerm: string): Promise<void> => {
    void (await refetch({
      variables: { searchTerm },
    }));
  };

  return {
    employees: data?.employeePreview || [],
    error,
    loading,
    search,
  };
};
