import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Typography } from 'antd';
import classNames from 'classnames';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { SuitCaseIcon } from 'components/icons';
import { Analytics } from 'constants/analytics';
import { VMS_GENERAL } from '../../../config';
import { useVms } from './query';
import styles from './styles.less';

const { Text } = Typography;

export const VMSBlock: FC<{
  login: string;
}> = ({ login }) => {
  const result = useVms(login);

  const { error, data, loading } = result;

  const TITLE = 'VMS';

  const { t } = useTranslation('translation');

  const connected = !!data?.vms?.hasActiveStatusVms;

  const activated = connected ? t('Active') : t('not joined to VMS');

  const onClick = (): void => {
    window.open(VMS_GENERAL, '_blank')?.focus();
  };

  if (!error && !data?.vms) {
    return (
      <Card
        icon={<SuitCaseIcon />}
        iconClassNames={styles['icon-class']}
        title={TITLE}
        containerClassNames={styles['card']}
        onClick={onClick}
        analyticsDomainName={Analytics.VMS}
        tags={
          <Tag
            className={classNames(
              styles['tag'],
              connected ? styles['active'] : styles['blocked'],
            )}
          >
            {activated}
          </Tag>
        }
      >
        <Header level={1} className={styles['days-text']}>
          ...
        </Header>
        <Text type="secondary">No information</Text>
      </Card>
    );
  }

  return (
    <Card
      loading={loading}
      error={error}
      title={TITLE}
      tags={
        <Tag
          className={classNames(
            styles['tag'],
            connected ? styles['active'] : styles['blocked'],
          )}
        >
          {activated}
        </Tag>
      }
      icon={<SuitCaseIcon />}
      iconClassNames={styles['icon-class']}
      containerClassNames={styles['card']}
      onClick={onClick}
      analyticsDomainName={Analytics.VMS}
    >
      <Header level={1} className={styles['days-text']}>
        {connected
          ? data?.vms?.availableWorkingDays
          : data?.vms?.accumulatedCalendarDays}
      </Header>
      <Header level={4} className={styles['days-text']}>
        {connected ? t(' work days') : t(' calendar days')}
      </Header>
      <Text type="secondary">
        {connected
          ? t('available for vacation')
          : t('accumulated for vacation')}
      </Text>
    </Card>
  );
};
