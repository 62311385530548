import { QueryResult, gql, useQuery } from '@apollo/client';
import { EpEmployee } from 'types/graphql-types';

type EpDataResult = {
  employee: EpEmployee;
};

export const GET_EP = gql`
  query GetEmployee($login: String!) {
    employee(login: $login) {
      experienceInsideCompany
    }
  }
`;

export const useEp = (login?: string): QueryResult<EpDataResult> =>
  useQuery<EpDataResult>(GET_EP, {
    returnPartialData: true,
    skip: !login,
    variables: { login },
  });
