import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { StartCircleIcon } from 'components/icons';
import { Analytics } from 'constants/analytics';
import { EP } from 'src/config';
import { useEp } from './query';
import styles from './styles.less';

const { Text } = Typography;

export const EPBlock: FC<{ login: string }> = ({ login }) => {
  const { error, loading, data } = useEp(login);
  const { t } = useTranslation('translation');

  const onClick = (): void => {
    window.open(EP, '_blank')?.focus();
  };

  if (!error && !data?.employee) {
    return (
      <Card
        icon={<StartCircleIcon />}
        iconClassNames={styles['icon-class']}
        title="Employee Portal"
        containerClassNames={styles['card']}
        onClick={onClick}
        loading={loading}
        analyticsDomainName={Analytics.EP}
      >
        <Header level={1} className={styles['header-text']}>
          ...
        </Header>
        <Text type="secondary">No information</Text>
      </Card>
    );
  }

  return (
    <Card
      error={error}
      title="EP"
      loading={loading}
      icon={<StartCircleIcon />}
      iconClassNames={styles['icon-class']}
      containerClassNames={styles['card']}
      onClick={onClick}
      analyticsDomainName={Analytics.EP}
    >
      <Header className={styles['text-align-left']}>
        {data?.employee?.experienceInsideCompany}
      </Header>
      <Text type="secondary">{t('years of experience in the company')}</Text>
    </Card>
  );
};
