/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { Alert, Col, PageHeader, Row, Spin, Typography } from 'antd';
import { Header } from 'components/header';
import { GraduationCapIcon } from 'components/icons';
import { EpEmployee } from 'types/graphql-types';
import styles from './styles.less';

const NO_DATE_SYMBOLS = '...';
const NO_DATA_SYMBOL = '—';

const { Text } = Typography;

type PersonalBlockProps = {
  loading?: boolean;
  error?: ApolloError;
  employee?: EpEmployee;
};

const getRegistrationLocation = ({
  contractCountry,
  contractCity,
}: EpEmployee): string => {
  const hasData = !!(contractCountry || contractCity);

  if (!hasData) {
    return NO_DATA_SYMBOL;
  }

  const country = contractCountry || '';
  const city = contractCity || '';

  return `${country}, ${city}`;
};

export const PersonalBlock: FC<PersonalBlockProps> = ({
  loading,
  error,
  employee,
}) => {
  const { t } = useTranslation('translation');

  if (loading) {
    return (
      <PageHeader ghost={false}>
        <Spin />
      </PageHeader>
    );
  }

  if (error || !employee) {
    return (
      <PageHeader ghost={false}>
        <Alert type="error" message={error?.message} />
      </PageHeader>
    );
  }

  const fullName = `${employee.firstName} ${employee.lastName}`;

  const contractsDates = `${
    employee.contractFromDate
      ? new Date(employee.contractFromDate as string).toLocaleDateString()
      : NO_DATE_SYMBOLS
  } - ${
    employee.contractToDate
      ? new Date(employee.contractToDate as string).toLocaleDateString()
      : NO_DATE_SYMBOLS
  }`;

  const location =
    employee.country && employee.city
      ? `${employee.city}, ${employee.country}`
      : employee.city || employee.country || NO_DATA_SYMBOL;

  const registrationLocation = getRegistrationLocation(employee);

  const englishLevel = employee.englishLevel || NO_DATA_SYMBOL;

  return (
    <PageHeader ghost={false}>
      <Row>
        <Col span={24}>
          <Row className={styles['content-row']} gutter={[0, 50]}>
            <Col span={24}>
              <Row className={styles['title-container']}>
                <Col span={24} lg={{ span: 'auto' }} className={styles['col']}>
                  <Header level={2}>{fullName}</Header>
                </Col>
                <Col span={24} lg={{ span: 'auto' }} className={styles['col']}>
                  <div className={styles['sub-title']}>
                    <Header level={4}>{employee.qualification}</Header>
                    <Text type="secondary" className={styles['english-level']}>
                      <GraduationCapIcon fill="#606F8A" />
                      Eng {englishLevel}
                    </Text>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row className={styles['row-info']} gutter={[20, 0]}>
                <Col span={24} lg={{ span: 8 }}>
                  <Text type="secondary" className={styles['title']}>
                    {t('Contract')}
                  </Text>
                  <Text className={styles['text']}>{contractsDates}</Text>
                </Col>
                <Col span={24} lg={{ span: 8 }}>
                  <Text type="secondary" className={styles['title']}>
                    {t('Current location')}
                  </Text>
                  <Text className={styles['text']}>{location}</Text>
                </Col>
                <Col span={24} lg={{ span: 8 }}>
                  <Text type="secondary" className={styles['title']}>
                    {t('Was registered in')}
                  </Text>
                  <Text className={styles['text']}>{registrationLocation}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  );
};
