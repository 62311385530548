export enum Analytics {
  VMS_SICK_LEAVES = 'SickLeaves',
  EP = 'EP',
  ZP = 'ZP',
  VMS = 'VMS',
  IO = 'Opportunities',
  QMS = 'QMSAssessment',
  WEBCV = 'WebCV',
  SOCIAL_BENEFITS = 'Benefits',
}

export const ANALYTICS_KEY = process.env.REACT_APP_ANALYTICS_KEY;
