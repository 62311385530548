import React, { FC } from 'react';
import {
  ApolloProvider as Apollo,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useMsal } from '@azure/msal-react';
// import { Spin } from 'antd';
import { App } from './app';
import { loginSilentRequest } from './config';

export const ApolloProvider: FC = () => {
  const { instance, accounts } = useMsal();

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_GRAPHQL_URI}`,
  });

  const authLink = setContext(async (_, { headers }) => {
    await instance.handleRedirectPromise();

    try {
      const result = await instance.acquireTokenSilent({
        ...loginSilentRequest,
        account: accounts[0],
      });

      return {
        headers: {
          ...headers,
          authorization: result ? `Bearer ${result.accessToken}` : '',
        } as unknown,
      };
    } catch {
      return instance.loginRedirect({
        ...loginSilentRequest,
        state: window.location.href,
      });
    }
  });

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        EmployeeVacation: {
          merge: true,
        },
        EpEmployee: {
          merge: true,
        },
      },
    }),
    connectToDevTools: true,
    link: authLink.concat(httpLink),
  });

  return (
    <Apollo client={client}>
      <App />
    </Apollo>
  );
};
