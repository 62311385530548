import { QueryResult, gql, useQuery } from '@apollo/client';
import { QmsData } from 'types/graphql-types';

type QmsDataResult = {
  qms?: QmsData;
};

const GET_QMS = gql`
  query GetQms($login: String!) {
    qms(login: $login) {
      login
      status
      dueDate
      progress
    }
  }
`;

export const useQms = (login?: string): QueryResult<QmsDataResult> =>
  useQuery<QmsDataResult>(GET_QMS, {
    returnPartialData: true,
    skip: !login,
    variables: { login },
  });
