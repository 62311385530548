import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useEmployee } from 'hooks';
import styles from './styles.less';

type IProps = {
  readonly collapsed?: boolean;
};

export const UserMenu = ({ collapsed }: IProps): React.ReactNode => {
  const { employee } = useEmployee();

  const username = useMemo(() => {
    const name = `${employee?.firstName || ''} ${employee?.lastName || ''}`;

    return collapsed ? name[0] : name;
  }, [collapsed, employee]);

  return (
    <div className={styles.containerUserMenu}>
      <div className={classNames(styles.username, collapsed && [styles.short])}>
        <div>{username}</div>
      </div>
    </div>
  );
};
