import { QueryResult, gql, useQuery } from '@apollo/client';
import { EmployeeLinksCv } from 'types/graphql-types';

export type WebCvDataReturn = {
  webcv: EmployeeLinksCv;
};

const GET_WEBCV = gql`
  query getWebcv($login: String!) {
    webcv(login: $login) {
      updatedAt
      link
    }
  }
`;

export const useWebCvData = (login?: string): QueryResult<WebCvDataReturn> =>
  useQuery<WebCvDataReturn>(GET_WEBCV, {
    skip: !login,
    variables: { login },
  });
