import { useEffect, useState } from 'react';

type DeviceType = {
  isDesktop: boolean;
  isLaptop: boolean;
  isMobile: boolean;
  isTablet: boolean;
};

export const useDevice = (): DeviceType => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const DESKTOP_WIDTH = 1024;
  const LAPTOP_WIDTH = 768;
  const MOBILE_WIDTH = 480;

  useEffect(() => {
    const onResize = (): void => setDeviceWidth(window.innerWidth);

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  const isDesktop = deviceWidth > DESKTOP_WIDTH;
  const isLaptop = deviceWidth >= LAPTOP_WIDTH;
  const isMobile = deviceWidth <= MOBILE_WIDTH;
  const isTablet = !isLaptop && !isMobile;

  return { isDesktop, isLaptop, isMobile, isTablet };
};
