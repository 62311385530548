import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import classNames from 'classnames';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { EditIcon } from 'components/icons';
import { Analytics } from 'constants/analytics';
import { WEBCV } from 'src/config';
import { useWebCvData } from './query';
import styles from './styles.less';

const { Text } = Typography;

export const WebCVBlock: FC<{ login: string }> = ({ login }) => {
  const { data, error, loading } = useWebCvData(login);

  const TITLE = 'WebCV';
  const defaultCVLink = WEBCV;
  const { t } = useTranslation('translation');
  const CV = data?.webcv;
  const CVLink = CV?.link;
  const hasCV = !!CVLink;

  const onClick = (): void => {
    window.open(CVLink || defaultCVLink, '_blank')?.focus();
  };

  if (!error && !hasCV) {
    return (
      <Card
        icon={<EditIcon />}
        iconClassNames={styles['icon-class']}
        title={TITLE}
        containerClassNames={styles['card']}
        onClick={onClick}
        analyticsDomainName={Analytics.WEBCV}
      >
        <Header level={1} className={styles['text-align-left']}>
          Oh!
        </Header>
        <Text type="secondary" className={styles['no-cv-message']}>
          {"You don't have any primary CV right now"}
        </Text>
      </Card>
    );
  }

  const updatedAt = new Date(CV?.updatedAt as number).toLocaleDateString(
    'en-US',
    {
      day: 'numeric',
      month: 'short',
      year: '2-digit',
    },
  );

  return (
    <Card
      icon={<EditIcon />}
      title={TITLE}
      loading={loading}
      error={error}
      iconClassNames={styles['icon-class']}
      containerClassNames={styles['card']}
      onClick={onClick}
      analyticsDomainName={Analytics.WEBCV}
    >
      <Header className={styles['text-align-left']}>
        {hasCV ? updatedAt : 'Oh!'}
      </Header>
      <Text
        type="secondary"
        className={classNames(!hasCV && styles['warning'])}
      >
        {hasCV
          ? t('Last update')
          : t("You don't have any primary CV right now")}
      </Text>
    </Card>
  );
};
