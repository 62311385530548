import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { PageHeader, Spin, Typography } from 'antd';
import { Header } from 'components/header';
import { LinkedInLogoIcon } from 'components/icons';
import { Link } from 'components/link';
import { useHeader } from './query';
import styles from './styles.less';

const { Text } = Typography;

export const HeaderBlock: FC = () => {
  const { data, error, loading } = useHeader();

  const { t } = useTranslation('translation');

  if (loading) {
    return (
      <PageHeader ghost={false}>
        <Spin />
      </PageHeader>
    );
  }

  if (error || !data) {
    return <PageHeader ghost={false}>Error</PageHeader>;
  }

  let contractFromDate = '';
  let contractToDate = '';
  let linkedIn = '';

  if (data?.employee) {
    const { employee } = data;

    linkedIn = employee.linkedIn || '';
    contractFromDate = employee.contractFromDate
      ? new Date(employee.contractFromDate as string).toLocaleDateString()
      : '';
    contractToDate = employee.contractToDate
      ? new Date(employee.contractToDate as string).toLocaleDateString()
      : '';
  }

  return (
    <PageHeader
      ghost={false}
      title={t('Dashboard')}
      subTitle={
        <Link name="linkedIn-link" to={linkedIn} flex>
          LinkedIn{' '}
          <LinkedInLogoIcon fill="#0A66C2" className={styles['ml-6']} />
        </Link>
      }
      extra={
        <>
          <Text>Contracts</Text>
          <Header level={5}>
            {contractFromDate}
            {' - '}
            {contractToDate}
          </Header>
        </>
      }
      className={styles['p-20']}
    ></PageHeader>
  );
};
