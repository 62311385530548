import { QueryResult, gql, useQuery } from '@apollo/client';
import { EmployeeVacation } from 'types/graphql-types';

type SickLeaves = {
  vms: EmployeeVacation;
};

const GET_VACATIONS_DATA = gql`
  query GetSickLeaves($login: String!) {
    vms(login: $login) {
      avilableSocialSickLeavesDuration
      socialSickLeaveDurationType
    }
  }
`;

export const useSickLeaves = (login?: string): QueryResult<SickLeaves> =>
  useQuery<SickLeaves>(GET_VACATIONS_DATA, {
    returnPartialData: true,
    skip: !login,
    variables: { login },
  });
