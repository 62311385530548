import React from 'react';
import { Skeleton } from 'antd';
import styles from './styles.less';

export type UserInfoProps = {
  avatarImg?: string;
  firstName: string;
  lastName: string;
  login: string;
  qualification?: string;
  onClick?: () => void;
  loading?: boolean;
};

export const UserInfo = ({
  avatarImg,
  firstName,
  lastName,
  login,
  qualification,
  onClick,
  loading,
}: UserInfoProps): React.ReactNode => {
  const username = `${firstName || ''} ${lastName || ''}`;

  return (
    <div className={styles.container} onClick={onClick}>
      <Skeleton avatar title={false} loading={loading}>
        {avatarImg && (
          <div className={styles.avatar}>
            <img src={avatarImg} alt={username} />
          </div>
        )}
        <div>
          <div className={styles.userInfo}>
            <h4 className={styles.login}>{login}</h4>
            <span className={styles.qualification}>{qualification}</span>
          </div>
          <h4 className={styles.userName}>{username}</h4>
        </div>
      </Skeleton>
    </div>
  );
};
