import React, { FC } from 'react';
import ModalContainer from 'react-modal-promise';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import { SideMenu } from 'components/side-menu';
import { ROOT, VIEW_DASHBOARD } from 'constants/routes';
import { Dashboard } from 'pages/dashboard';
import { ViewDashboard } from 'pages/view-dashboard';
import './app.less';

const { Content } = Layout;

export const App: FC = () => (
  <Layout className="app">
    <SideMenu />
    <Content>
      <Routes>
        <Route path={ROOT} element={<Dashboard />} />
        <Route path={VIEW_DASHBOARD} element={<ViewDashboard />} />
      </Routes>
    </Content>
    <ModalContainer />
  </Layout>
);
