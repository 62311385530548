import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { GiftIcon } from 'components/icons';
import { Analytics } from 'constants/analytics';
import { EmployeeCompensationRequest } from 'types/graphql-types';
import styles from 'user/social-benefit-block/styles.less';
import { SOCIAL_BENEFITS } from '../../../config';

const { Text } = Typography;

export const SocialBenefitBlock: FC<{
  benefits: EmployeeCompensationRequest[];
}> = ({ benefits = [] }) => {
  const { t } = useTranslation('translation');

  const onBenefitsOpen = (): void => {
    window.open(SOCIAL_BENEFITS, '_blank')?.focus();
  };

  return (
    <Card
      title={t('Benefits')}
      icon={<GiftIcon />}
      iconClassNames={styles['icon-class']}
      containerClassNames={styles['card']}
      onClick={onBenefitsOpen}
      analyticsDomainName={Analytics.SOCIAL_BENEFITS}
    >
      <Header level={1} className={styles['d-inline']}>
        {benefits.length}
      </Header>
      <Text type="secondary">{t('of applications in pending status')}</Text>
    </Card>
  );
};
