import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import styles from './styles.less';

type IProps = {
  readonly icon: ReactNode;
  readonly text: string;
  readonly collapsed: boolean;
  readonly onClick?: () => void;
};

export const MenuButton = ({
  icon,
  text,
  collapsed,
  onClick,
}: IProps): React.ReactNode => (
  <Tooltip title={collapsed ? text : null} placement="right">
    <button type="button" className={styles.navigationItem} onClick={onClick}>
      {icon}
      <span className={styles.text}>{text}</span>
    </button>
  </Tooltip>
);
