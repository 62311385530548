import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { UsersIcon } from 'components/icons';
import { Analytics } from 'constants/analytics';
import { IO } from 'src/config';
import { useOpportunities } from './query';
import styles from './styles.less';

const { Text } = Typography;

export const OpportunitiesBlock: FC<{ login: string }> = ({ login }) => {
  const { error, loading, data } = useOpportunities(login);

  const { t } = useTranslation('translation');

  const onClick = (): void => {
    window.open(IO, '_blank')?.focus();
  };

  if (!error && !data?.io) {
    return (
      <Card
        icon={<UsersIcon />}
        title={t('Opportunities')}
        iconClassNames={styles['icon-class']}
        containerClassNames={styles['card']}
        onClick={onClick}
        analyticsDomainName={Analytics.IO}
      >
        <Header level={1} className={styles['text-align-left']}>
          ...
        </Header>
        <Text type="secondary">No information</Text>
      </Card>
    );
  }

  return (
    <Card
      icon={<UsersIcon />}
      title={t('Opportunities')}
      loading={loading}
      error={error}
      iconClassNames={styles['icon-class']}
      containerClassNames={styles['card']}
      onClick={onClick}
      analyticsDomainName={Analytics.IO}
    >
      <Header className={styles['text-align-left']}>{data?.io.count}</Header>
      <Text type="secondary">{t('New opportunities')}</Text>
    </Card>
  );
};
