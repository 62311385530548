/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, PageHeader, Row, Typography } from 'antd';
import { Header } from 'components/header';
import {
  AddressCardIcon,
  CalendarIcon,
  EmptyAvatarIcon,
  LinkedInLogoIcon,
  MailIcon,
  MapMarkerIcon,
  UsersIcon,
} from 'components/icons';
import { Link } from 'components/link';
import { List } from 'components/list/list';
import { ListItem } from 'components/list/list-item';
import { useEmployee } from 'hooks/use-employee';
import { ProfilePicture } from './profile-picture';
import styles from './styles.less';

const { Text } = Typography;

type PersonalInfoProps = {
  extra?: React.ReactNode;
  shareButton?: React.ReactNode;
  allowEditAvatar?: boolean;
};

export const PersonalInfo: FC<PersonalInfoProps> = ({
  extra,
  shareButton,
  allowEditAvatar,
}) => {
  const { employee, error } = useEmployee();

  const {
    t,
    i18n: { language },
  } = useTranslation('translation');

  if (error || !employee) {
    return (
      <PageHeader ghost={false}>
        <Alert type="error" message={error?.message} showIcon />
      </PageHeader>
    );
  }

  const skillTextClass = styles['personal-info-text'];
  const lastUpdated = employee?.lastUpdated
    ? new Date(employee.lastUpdated as string)
    : new Date('2020-01-01');

  const lastUpdateString = lastUpdated.toLocaleDateString(language, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const location =
    employee.country && employee.city
      ? `${employee.city}, ${employee.country}`
      : employee.city || employee.country || '—';

  return (
    <PageHeader ghost={false}>
      <Row>
        <Col
          span={24}
          lg={{ flex: '240px', order: 2, span: 6 }}
          className={styles['profile-img-container']}
        >
          <ProfilePicture
            photo={employee?.photo || ''}
            allowEdit={allowEditAvatar}
            placeholder={<EmptyAvatarIcon />}
          />
        </Col>
        <Col span={24} lg={{ flex: 'auto', span: 18 }}>
          <Row className={styles['name-info']}>
            <Header className={styles['name-header']}>{`${
              employee?.firstName || ''
            } ${employee?.lastName || ''}`}</Header>
            {extra}
          </Row>
          <Row gutter={[0, 16]}>
            <Col span={24} sm={{ span: 12 }}>
              <List className={styles['list']}>
                <ListItem className={styles['mb-16']}>
                  <AddressCardIcon fill="#69AAFF" />
                  <Text type="secondary" className={skillTextClass}>
                    {employee?.qualification || '—'}
                  </Text>
                </ListItem>
                <ListItem className={styles['mb-16']}>
                  <MailIcon fill="#69AAFF" />
                  <Text type="secondary" className={skillTextClass}>
                    {`${employee.login.toLowerCase()}@itransition.com`}
                  </Text>
                </ListItem>
                <ListItem>
                  <CalendarIcon fill="#69AAFF" />
                  <Text type="secondary" className={skillTextClass}>
                    {`${employee.experienceInsideCompany}/${employee.experienceOutsideCompany} years of experience in itransition`}
                  </Text>
                </ListItem>
              </List>
            </Col>
            <Col span={24} sm={{ offset: 1, span: 11 }}>
              <List className={styles['list']}>
                <ListItem className={styles['mb-16']}>
                  <UsersIcon fill="#69AAFF" />
                  <Text type="secondary" className={skillTextClass}>
                    {employee?.department || '—'}
                  </Text>
                </ListItem>
                <ListItem className={styles['mb-16']}>
                  <MapMarkerIcon fill="#69AAFF" />
                  <Text type="secondary" className={skillTextClass}>
                    {location}
                  </Text>
                </ListItem>
                <ListItem>
                  {employee?.linkedIn ? (
                    <Link to={employee.linkedIn} name="LinkedIn" flex external>
                      <LinkedInLogoIcon fill="#69AAFF" />
                      <Text type="secondary" className={skillTextClass}>
                        LinkedIn
                      </Text>
                    </Link>
                  ) : (
                    <>
                      <LinkedInLogoIcon fill="#69AAFF" />
                      <Text type="secondary" className={skillTextClass}>
                        —
                      </Text>
                    </>
                  )}
                </ListItem>
              </List>
            </Col>
          </Row>
          <Row className={styles['share-footer']}>
            {shareButton}
            <Text className={styles['share-footer-text']}>
              {t('Last update')} {lastUpdateString}
            </Text>
          </Row>
        </Col>
      </Row>
    </PageHeader>
  );
};
