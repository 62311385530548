import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginSilentRequest } from 'src/config';

export const useToken = (): string => {
  const [token, setToken] = useState('');
  const { instance, accounts } = useMsal();

  useEffect(() => {
    (async () => {
      await instance.handleRedirectPromise();

      try {
        const result = await instance.acquireTokenSilent({
          ...loginSilentRequest,
          account: accounts[0],
        });

        setToken(result.accessToken);
      } catch {
        return instance.loginRedirect({
          ...loginSilentRequest,
          state: window.location.href,
        });
      }
    })();
  }, [accounts, instance]);

  return token;
};
