export const ROOT = '/';
export const SKILLS = `${ROOT}profile`;
export const VIEW_PROFILE = `${ROOT}profile/:linkHash`;
export const VIEW_DASHBOARD = `${ROOT}dashboard/:linkHash`;

export const getViewProfileLink = (linkHash: string): string =>
  `${ROOT}profile/${linkHash}`;

export const getViewDashboardLink = (linkHash: string): string =>
  `${ROOT}dashboard/${linkHash}`;
