import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { ANALYTICS_KEY } from 'constants/analytics';
import { translations } from 'constants/translations';
import i18next from 'i18next';
import { ApolloProvider } from './apollo-provider';
import { msalConfig } from './config';
import './index.less';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
const publicClientApplication = new PublicClientApplication(msalConfig);

// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
TagManager.initialize({
  gtmId: ANALYTICS_KEY,
});

void i18next
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: 'en',
    resources: translations,
  })
  .then(() => {
    root.render(
      <BrowserRouter>
        <React.StrictMode>
          <I18nextProvider i18n={i18next}>
            <MsalProvider instance={publicClientApplication}>
              <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
              >
                <ApolloProvider />
              </MsalAuthenticationTemplate>
            </MsalProvider>
          </I18nextProvider>
        </React.StrictMode>
      </BrowserRouter>,
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
