/* eslint-disable sonarjs/no-duplicate-string */
// todo refactor translate
export const translations = {
  en: {
    translation: {
      Active: 'Active',
      Add: 'Add',
      'Add certificate': 'Add certificate',
      'Add language': 'Add language',
      'Add skills': 'Add skills',
      Browse: 'Browse',
      Cancel: 'Cancel',
      'Certificate title': 'Certificate title',
      Certificates: 'Certificates',
      'Current location': 'Current location',
      Dashboard: 'Dashboard',
      'Delete {{target}}?': 'Delete {{target}}?',
      Domains: 'Domains',
      Edit: 'Edit',
      'Edit language skills': 'Edit language skills',
      'Edit personal info': 'Edit personal info',
      'Error occurred while uploading the image.':
        'Error occurred while uploading the image.',
      'File format PDF, PNG, JPEG, weight < 2MB':
        'File format PDF, PNG, JPEG, weight < 2MB',
      'Increase your discoverability > Add your skills':
        'Increase your discoverability > Add your skills',
      Language: 'Language',
      'Language Skills': 'Language Skills',
      'Last update': 'Last update',
      Level: 'Level',
      Location: 'Location',
      'New opportunities': 'New opportunities',
      'Next Career Day': 'Next Career Day',
      On: 'On',
      Opportunities: 'Opportunities',
      Platforms: 'Platforms',
      'Primary technology': 'Primary technology',
      'Primary technology helps to find opportunities that match your skills.':
        'Primary technology helps to find opportunities that match your skills.',
      'Profile strength': 'Profile strength',
      Save: 'Save',
      'Select your location': 'Select your location',
      'Select your primary technology': 'Select your primary technology',
      'Share my profile': 'Share my profile',
      'Skills and Achievements': 'Skills and Achievements',
      'Social sick leaves': 'Social sick leaves',
      Specialization: 'Specialization',
      'Take Sick Day': 'Take Sick Day',
      'Tax in': 'Tax in',
      Technologies: 'Technologies',
      'Was registered in': 'Was registered in',
      'What are your hobbies and interests?':
        'What are your hobbies and interests?',
      'Yes, delete': 'Yes, delete',
      'You are going to delete {{target}} from your skills.':
        'You are going to delete {{target}} from your skills.',
      'You can only upload images of types':
        'You can only upload images of types',
      'Your domains': 'Your domains',
      'Your platforms': 'Your platforms',
      'Your technologies': 'Your technologies',
      'Your technology': 'Your technology',
      'Your {{platform}} skill level': 'Your {{platform}} skill level',
      'Your {{tech}} skill level': 'Your {{tech}} skill level',
      available: 'available',
      'available for vacation': 'available for vacation',
      certificates: 'certificates',
      days: 'days',
      more: 'more',
      'not joined to VMS': 'not joined to VMS',
      'of applications in pending status': 'of applications in pending status',
      planned: 'planned',
      'work days': 'work days',
      'years of experience in the company':
        'years of experience in the company',
    },
  },

  ru: {
    translation: {
      Active: 'Активен',
      Add: 'Добавить',
      'Add certificate': 'Добавить сертификат',
      'Add language': 'Добавить язык',
      'Add skills': 'Добавить навыки',
      'Are you sure you want to delete your profile picture?':
        'Вы действительно хотите удалить фото вашего профиля?',
      Browse: 'Browse',
      Cancel: 'Отмена',
      'Certificate title': 'Название сертификата',
      Certificates: 'Сертификаты',
      'Current location': 'Текущая локация',
      Dashboard: 'Дашборд',
      'Delete picture?': 'Удалить фото?',
      'Delete {{target}}?': 'Удалть {{target}}?',
      Domains: 'Домены',
      Edit: 'Изменить',
      'Edit language skills': 'Изменить языковые уровни',
      'Edit personal info': 'Изменить личную информацию',
      'Error occurred while uploading the image.':
        'Во время загрузки изображения произошла ошибка.',
      'File format PDF, PNG, JPEG, weight < 2MB':
        'Формат файла PDF, PNG, JPEG, weight < 2MB',
      'Increase your discoverability > Add your skills':
        'Увеличьте обнаружаемость > Добавьте навыки',
      Language: 'Язык',
      'Language Skills': 'Уровни языков',
      'Last update': 'Последнее обновление',
      Level: 'Уровень',
      Location: 'Локация',
      'New opportunities': 'Новые возможности',
      'Next Career Day': 'Следующий день карьеры',
      On: 'Подключен',
      Opportunities: 'Возможности',
      Platforms: 'Платформы',
      'Primary technology': 'Главная технология',
      'Primary technology helps to find opportunities that match your skills.':
        'Главная технология помогает найти возможности, соответствующие вашим навыкам',
      'Profile strength': 'Сила профиля',
      Save: 'Сохранить',
      'Select your location': 'Выберете вашу локацию',
      'Select your primary technology': 'Выберете вашу главную технологию',
      'Share my profile': 'Поделиться профилем',
      'Skills and Achievements': 'Навыки и достижения',
      'Social sick leaves': 'Социальные сик ливы',
      Specialization: 'Специализация',
      'Take Sick Day': 'Взять сик лив',
      'Tax in': 'Налог в',
      Technologies: 'Технологии',
      'Was registered in': 'Зарегистрирован в ',
      'What are your hobbies and interests?': 'Какие у вас хобби и интересы?',
      'Wrong value': 'Неверное значение',
      'Yes, delete': 'Да, удалить',
      'You are going to delete {{target}} from your skills.':
        'Вы собираетесь удалить {{target}} из ваших навыков.',
      'You can only upload images of types':
        'Вы можете загрузить изображение только типов',
      'Your domains': 'Ваши домены',
      'Your platforms': 'Ваши платформы',
      'Your technologies': 'Ваши технологии',
      'Your technology': 'Ваши технологии',
      'Your {{platform}} skill level': 'Ваш уровень {{platform}}',
      'Your {{tech}} skill level': 'Ваш уровень {{tech}}',
      available: 'доступно',
      'available for vacation': 'доступно для отпуска',
      certificates: 'сертификатов',
      days: 'дней',
      more: 'ещё',
      'not joined to VMS': 'не присоединен к VMS',
      'of applications in pending status': 'заявок в статусе ожидания',
      planned: 'planned',
      'work days': 'рабочих дней',
      'years of experience in the company': 'стаж в компании',
    },
  },
};
