export enum UserRole {
  EMPLOYEE = 'Employee',
  RESOURCE_MANAGER = 'ResourceManager',
  HEAD_OF_DEPARTMENT = 'HeadOfDepartment',
  PROD_BOARD = 'ProdBoard',
  ADMIN = 'Admin',
  SYSTEM_USER = 'SystemUser',
}

export const allowSearchEmployees = (role: string): boolean =>
  (
    [
      UserRole.RESOURCE_MANAGER,
      UserRole.HEAD_OF_DEPARTMENT,
      UserRole.PROD_BOARD,
      UserRole.ADMIN,
    ] as string[]
  ).includes(role);
