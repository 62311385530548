import React, { FC } from 'react';
import { Typography } from 'antd';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { ChartPieIcon } from 'components/icons';
import { Analytics } from 'constants/analytics';
import { ZP_INFO } from 'src/config';
import styles from './styles.less';

const { Text } = Typography;

export const ZPBlock: FC = () => {
  const TITLE = 'Finance';

  const onClick = (): void => {
    window.open(ZP_INFO, '_blank')?.focus();
  };

  return (
    <Card
      title={TITLE}
      icon={<ChartPieIcon />}
      iconClassNames={styles['icon-class']}
      containerClassNames={styles['card']}
      onClick={onClick}
      analyticsDomainName={Analytics.ZP}
    >
      <Header level={1} className={styles['text-align-left']}>
        $X XXX
      </Header>
      <Text type="secondary">In progress</Text>
    </Card>
  );
};
