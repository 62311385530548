const VMS = process.env.REACT_APP_VMS_LINK;

export const IO = process.env.REACT_APP_IO_LINK;
export const EP = process.env.REACT_APP_EP_LINK;
export const QMS = process.env.REACT_APP_QMS_LINK;
export const WEBCV = process.env.REACT_APP_WEBCV_LINK;
export const VMS_GENERAL = `${VMS}/GeneralInfo`;
export const VMS_SICK_LEAVES = `${VMS}/ui/personal/sick-leave`;
export const ZP_INFO = `${EP}/Finance`;
export const SOCIAL_BENEFITS = process.env.REACT_APP_SOCIAL_BENEFITS_LINK;
