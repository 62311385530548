import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Typography } from 'antd';
import classNames from 'classnames';
import { Card } from 'components/card';
import { Header } from 'components/header';
import { StairsIcon } from 'components/icons';
import { Analytics } from 'constants/analytics';
import { QMS } from 'src/config';
import { useQms } from './query';
import styles from './styles.less';

const { Text } = Typography;

export const QMSBlock: FC<{ login: string }> = ({ login }) => {
  const { error, loading, data } = useQms(login);
  const { t } = useTranslation('translation');

  const onClick = (): void => {
    window.open(QMS, '_blank')?.focus();
  };

  if (!error && !data?.qms) {
    return (
      <Card
        icon={<StairsIcon />}
        iconClassNames={styles['icon-class']}
        title="QMS Assessment"
        containerClassNames={styles['card']}
        onClick={onClick}
        loading={loading}
        analyticsDomainName={Analytics.QMS}
      >
        <Header level={1} className={styles['header-text']}>
          ...
        </Header>
        <Text type="secondary">No information</Text>
      </Card>
    );
  }

  const lastAssessmentDate = new Date(
    data?.qms?.dueDate as string,
  ).toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  });
  const percentageProgress = data?.qms?.progress || '0%';
  const completed = data?.qms?.progress === '100%';

  return (
    <Card
      error={error}
      title="QMS Assessment"
      loading={loading}
      icon={<StairsIcon />}
      iconClassNames={styles['icon-class']}
      tags={
        <Tag
          className={classNames(
            styles['tag'],
            completed ? styles['completed'] : styles['in-progress'],
          )}
        >
          {completed ? 'Completed' : 'In progress'}
        </Tag>
      }
      containerClassNames={styles['card']}
      onClick={onClick}
      analyticsDomainName={Analytics.QMS}
    >
      <Header level={1} className={styles['header-text']}>
        {completed ? lastAssessmentDate : `${percentageProgress}`}
      </Header>
      <Text type="secondary">
        {completed
          ? t('date of the latest assessment')
          : t('progress of current assessment')}
      </Text>
    </Card>
  );
};
