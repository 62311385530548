import { QueryResult, gql, useQuery } from '@apollo/client';

type OpportunitiesCount = {
  io: {
    count: number;
  };
};

const GET_OPPORTUNITIES = gql`
  query GetOpportunities($login: String!) {
    io(login: $login) {
      count
    }
  }
`;

export const useOpportunities = (
  login?: string,
): QueryResult<OpportunitiesCount> =>
  useQuery<OpportunitiesCount>(GET_OPPORTUNITIES, {
    skip: !login,
    variables: {
      login,
    },
  });
