import React, { FC, MouseEvent } from 'react';
import { ApolloError } from '@apollo/client';
import {
  Avatar,
  Col,
  PageHeader,
  PageHeaderProps,
  Row,
  Spin,
  Typography,
} from 'antd';
import classNames from 'classnames';
import { Header } from 'components/header';
import styles from './styles.less';

export const Card: FC<CardProps> = ({
  children,
  error,
  loading,
  title,
  icon,
  iconClassNames,
  extra,
  tags,
  containerClassNames,
  onClick,
  analyticsDomainName,
}) => {
  if (loading) {
    return (
      <PageHeader ghost={false}>
        <Spin />
      </PageHeader>
    );
  }

  const SPAN_CONTENT = 14;
  const SPAN_ICON = 10;
  const AVATAR_SIZE = 70;

  const avatarClasses = classNames(styles['icon'], iconClassNames);

  const analyticsId = analyticsDomainName
    ? `analytics-domain-${analyticsDomainName}`
    : '';

  if (error) {
    return (
      <PageHeader
        ghost={false}
        title={title}
        className={classNames(styles['card'], containerClassNames)}
      >
        <Row className={styles['spread']} align="bottom">
          <Col span={SPAN_CONTENT}>
            <Header level={1} className={styles['unavailable-header']}>
              sorry...
            </Header>
            <Typography.Text type="secondary">
              Service unavailable
            </Typography.Text>
          </Col>
          <Col span={SPAN_ICON} className={styles['icon-container']}>
            <Avatar icon={icon} size={AVATAR_SIZE} className={avatarClasses} />
          </Col>
        </Row>
      </PageHeader>
    );
  }

  return (
    <div
      onClick={onClick}
      className={styles['card-container']}
      id={analyticsId}
    >
      <PageHeader
        ghost={false}
        title={title}
        extra={extra}
        tags={tags}
        className={classNames(styles['card'], containerClassNames)}
      >
        <Row className={styles['spread']} align="bottom">
          <Col span={SPAN_CONTENT}>{children}</Col>
          <Col span={SPAN_ICON} className={styles['icon-container']}>
            <Avatar icon={icon} size={AVATAR_SIZE} className={avatarClasses} />
          </Col>
        </Row>
      </PageHeader>
    </div>
  );
};

type CardProps = {
  children: React.ReactNode | React.ReactNode[];
  icon: React.ReactNode;
  error?: ApolloError;
  loading?: boolean;
  title: React.ReactNode;
  tags?: PageHeaderProps['tags'];
  extra?: React.ReactNode;
  iconClassNames?: string;
  containerClassNames?: string;
  onClick?: (event: MouseEvent) => void;
  analyticsDomainName?: string;
};
