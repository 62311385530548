import { useCallback, useEffect, useState } from 'react';
import { getUri, isBase64 } from 'utils';
import { useToken } from './use-token';

const VIEW_IMAGE_TIMEOUT = 500;

export const useImageBlobSrc = (
  photo: string,
): {
  clearImageResources: () => void;
  isImageLoading: boolean;
  src: string | null;
} => {
  const token = useToken();
  const [src, setSrc] = useState<string | null>(null);
  const [isImageLoading, setIsImageLoading] = useState<boolean>(true);

  const clearImageResources = useCallback(() => {
    if (src) {
      URL.revokeObjectURL(src);
      setSrc(null);
      setIsImageLoading(true);
    }
  }, [src]);

  const isBase64img = isBase64(photo);

  useEffect(() => {
    (async () => {
      setIsImageLoading(true);

      if (isBase64img) {
        setSrc(photo);
        setTimeout(() => setIsImageLoading(false), VIEW_IMAGE_TIMEOUT);

        return;
      }

      if (photo && token && !src) {
        const url = getUri(photo);
        const response = await fetchWithAuthentication(url, token);

        // Create an object URL from the data.
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        setSrc(objectUrl);
        setTimeout(() => setIsImageLoading(false), VIEW_IMAGE_TIMEOUT);
      }

      if (!photo) {
        setTimeout(() => setIsImageLoading(false), VIEW_IMAGE_TIMEOUT);
      }
    })();

    return clearImageResources;
  }, [photo, src, token, clearImageResources, isBase64img]);

  return { clearImageResources, isImageLoading, src };
};

function fetchWithAuthentication(
  url: string,
  authToken: string,
): Promise<Response> {
  const headers = new Headers();

  headers.set('Authorization', `Bearer ${authToken}`);

  return fetch(url, { headers });
}
