import { QueryResult, gql, useQuery } from '@apollo/client';
import { UserRole } from 'types/user-roles';

type UseQueryRoleResponse = {
  role: {
    role: UserRole;
  };
};

const GET_ROLE = gql`
  query GetRole {
    role {
      role
    }
  }
`;

export const useRole = (): QueryResult<UseQueryRoleResponse> =>
  useQuery<UseQueryRoleResponse>(GET_ROLE);
