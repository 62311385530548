import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.less';

export const Link: FC<LinkProps> = ({
  className,
  children,
  to,
  external = false,
  flex = false,
}) => {
  const classes = classNames(
    styles.link,
    external && styles.external,
    flex && styles['d-flex'],
    className,
  );

  return (
    <a className={classes} href={to} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  );
};

type LinkProps = {
  name: string;
  to: string;
  className?: string;
  children?: React.ReactNode;
  external?: boolean;
  flex?: boolean;
};
