import { useMemo } from 'react';
import { useMsal } from '@azure/msal-react';

type LoginInput = {
  login: string;
};

export const useLoginInput = (): LoginInput => {
  const { accounts } = useMsal();
  const login = useMemo(
    () => accounts[0]?.username.split('@')[0]?.toUpperCase() || '',
    [accounts],
  );

  return { login };
};
